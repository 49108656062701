export const hrefs = {
  facebook: "",
  instagram: "",
  twitter: "https://x.com/VixFinanceApp",
  mail: "",
  discord: "",
  scan: "",
  telegram: "",
  linkedIn: "",
  reddit: "",
  medium: "",
  address: "",
  docs: "",
  whitePaper: "",
  contract: "",
};
