import React from "react"

import { Medias, routes } from "utils"

import "./Footer.scss"
import { Link, useSearchParams } from "react-router-dom"

export const Footer = (): JSX.Element => {
  const [search] = useSearchParams()

  return (
    <footer className="footer">
      <div className='footer-top'>
        <div className="footer-left">
          <Link className="footer-left-link" to={`${routes.privacy}?${search.toString()}`}>
            Privacy Policy
          </Link>
          <Link className="footer-left-link" to={`${routes.terms}?${search.toString()}`}>
            Terms & Conditions
          </Link>
          <Link className="footer-left-link" to={`${routes.cookies}?${search.toString()}`}>
            Cookie Policy
          </Link>
        </div>
        <p className="footer-reserved">Copyright © 2024 VIX Finance. All rights reserved</p>
        <Medias />
      </div>

      <div className='footer-bottom'>
        <div className='footer-bottom-block'>
          <dt className='footer-bottom-title'>Email:</dt>
          <dd className='footer-bottom-data'><a href='mailto:support@vix.finance'>support@vix.finance</a></dd>
        </div>
        <div className='footer-bottom-block'>
          <dt className='footer-bottom-title'>Address:</dt>
          <dd className='footer-bottom-data'><address>
            Level 4 Riverside Quay, 1 Southbank Blvd, Southbank VIC 3006
          </address></dd>
        </div>
      </div>
    </footer>
  )
}
