import React from "react"

import "./Loading.scss"

export const Loading = (): JSX.Element => (
  <div className="loading">
    <div className="loading-backdrop" />
    <div className="loader">
      <span></span>
    </div>
    <div className="loader">
      <span></span>
    </div>

    <div className="loader">
      <i></i>
    </div>
    <div className="loader">
      <i></i>
    </div>
  </div>
)
