import React, { useState } from "react"
import { Header, Footer, Collapse } from "components"

import "./FAQ.scss"
import imgMainBgSrc from "assets/images/main-bg.webp"


export const FAQ = (): JSX.Element => {
  const faqData = [
    {
      title: "How is Staking work on VIX Finance?",
      desc: "" +
        "VIX Finance is a cutting-edge, decentralized staking platform that offers unique opportunities for users. Leveraging the power of well-designed smart contracts, VIX Finance is able to identify innovative staking pools that offer high potential and attractive Annual Percentage Rates (APR) in the market. This allows users to earn through arbitrage.\n" +
        "\n" +
        "The smart contracts employed by VIX Finance are not only robust and reliable but they also provide a competitive edge. Owing to the strength of their smart contract mechanism, VIX Finance is capable of offering higher returns compared to other decentralized applications (dApps) in the market.\n" +
        "\n" +
        "In essence, VIX Finance transforms how staking works by combining safety, reliability, and higher earning potential under one platform. With VIX Finance, you're not just staking — you're optimizing your assets.",
    },
    {
      title: "How can I start staking on VIX Finance?",
      desc: "To begin Staking, you need to have a compatible wallet for your chosen cryptocurrency. After connecting to the wallet , you can deposit your tokens into it. Choose the amount of tokens you wish to stake ,and follow the instruction provided to stake your tokens.",
    },
    {
      title: "Is there a minimum amount required to stake on VIX Finance?",
      desc: "The minimum staking requirement on VIX Finance may be different depending on the specific staking pool .As such ,it is advisable to check the staking pool details for accurate information regarding the minimum staking requirements.",
    },
    {
      title: "Can I withdraw my staked tokens at VIX Finance at anytime?",
      desc: "Withdrawal policies on VIX Finance may be different based on each staking pool.",
    },
    {
      title: "Is my principal investment safe while staking on VIX Finance?",
      desc: "VIX Finance has harnessed the power of smart contracts and blockchain technology to provide a secure environment for staking.However, it is important to acknowledge that all cryptocurrency investments carry a certain level of risk.",
    },
    {
      title: "How are the referral reward calculated on VIX Finance?",
      desc: "Referral rewards on VIX Finance are calculated as an additional 15% bonus on the staking interest earned by your referral friends. To maximize your referral earnings , you can share your unique referral link with friends.",
    },
    {
      title: "Do I have to stake on VIX Finance to participate into the referral program?",
      desc: "To participate in the referral program on VIX Finance ,it is necessary to actively stake any currency on the platform ,It is important to note that an invitation will only be considered valid if you are participating in staking activities,If you are not actively staking ,the invitation will be deemed invalid.",
    },
    {
      title: "Can I track the performance of my referrals on VIX Finance?",
      desc: "VIX Finance provides a user-friendly dashboard for tracking the performance of referrals,which includes details such as the number of successful referrals, and referral friend's staking activities on VIX Finance.",
    },
    {
      title: "Is my personal information safe on VIX Finance?",
      desc: "VIX Finance prioritizes the privacy and security of it's users by implementing robust measures such as data encryption and secure authentication methods to safeguard their personal information ,It is recommended that users review the platform security features thoroughly to ensure their information is well protected.",
    },
    {
      title: "Do I need an referral code to access VIX Finance?",
      desc: "As of our recent policy updates, you no longer need an referral code to join the VIX Finance platform. This change is part of our ongoing commitment to make decentralized staking accessible to a wider audience.\n" +
         "\n" +
         "While the referral-only system helped us foster a secure and exclusive environment in the initial stages, we believe it's now time to open the gates to more individuals interested in decentralized staking. \n" +
         "\n" +
         "This doesn't mean we're compromising on our commitment to security and performance. Rest assured, we continue to implement rigorous checks and safeguards to ensure the platform remains secure and reliable for all users.\n" +
         "\n" +
         "Now, anyone with an interest in digital asset management and staking can enjoy the full features and benefits of VIX Finance, joining a community of forward-thinking individuals united by a shared interest in the future of decentralized finance."
    },
    {
      title: "Can I join VIX Finance without an referral code?",
      desc: "Yes, you can! In our efforts to make decentralized staking more accessible, we've recently updated our policy. Now, you're no longer required to have an referral code to join the VIX Finance platform.\n" +
         "\n" +
         "Previously, we used an referral-only system to ensure the security and exclusivity of our platform. While this system helped us cultivate a community of engaged and qualified individuals, we understand the importance of opening up access to a wider audience.\n" +
         "\n" +
         "This change doesn't compromise the security or functionality of our platform. We continue to ensure a safe and reliable environment for all our users. Now, more individuals interested in decentralized staking can explore the full features and benefits of VIX Finance, becoming part of our expanding community driven by the future of decentralized finance."
    },
    {
      title: "What are the benefits of having an referral code?",
      desc: "Previously, an referral code was required to join the exclusive VIX Finance platform. It represented an individual's access to a community of qualified stakers and offered exclusive opportunities and resources.\n" +
         "\n" +
         "However, as we've recently made VIX Finance accessible to a wider audience, referral codes are no longer required for joining. Our goal is to democratize access to decentralized staking, so more individuals can benefit from this innovative financial strategy.\n" +
         "\n" +
         "That said, rest assured that the quality of our community and platform remains top-notch. Even without an referral code, you'll have the opportunity to engage with other users, exchange insights, and collaborate on decentralized staking strategies. All users can benefit from the collective knowledge and expertise of our community, enabling them to make informed decisions and optimize their staking rewards. \n" +
         "\n" +
         "While the referral code is no longer a requirement, our commitment to creating an esteemed, secure, and inclusive community remains unwavering."
    }
  ]

  return (
    <div className="faq" style={{ backgroundImage: `url(${imgMainBgSrc})` }}>
      <Header />
      <div className="faq-content">
        <h1 className="faq-content-title">VIX Finance Frequently Asked Questions</h1>
        {faqData.map((item, index) => (
          <Collapse desc={item.desc} title={item.title} key={index} />
        ))}
      </div>
      <Footer  />
    </div>
  )
}
