import React, { useEffect, useState } from "react"

import imgMainSrc from "assets/images/main.webp"
import "./Main.scss"
import { Link, useSearchParams } from "react-router-dom"
import { getFromLocalStorage, mixins, moneyFormatter, routes, setToLocalStorage } from "../../utils"
import { useMedia } from "use-media"

type Props = {
  onEndLoad: () => void
}

export const Main = ({ onEndLoad }: Props): JSX.Element => {
  const [search] = useSearchParams()

  return (
    <main className="main">
      <div className="main-left">
        <div className="main-left-first">Amplify Your Cross-Chain Staking</div>
        <h1 className="main-left-title">
          Unleash the Power of Multi-Chain Staking and <span className="colored">Boost</span> Your Assets
        </h1>
        <p className="main-left-desc">Stake. Earn. Grow. Empowering Crypto Investors Across Multiple Blockchains</p>
        <div className="main-left-buttons">
          <Link to={`${routes.farms}?${search.toString()}`} className="main-left-buttons-item">
            Stake NOW
          </Link>
        </div>
      </div>
      <div className="main-right">
        <img onLoad={onEndLoad} src={imgMainSrc} alt="main" className="main-right-img" />
      </div>
    </main>
  )
}
